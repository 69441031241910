import { navigate } from "gatsby"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Flex, Text } from "rebass"
import LoginLayout from "../../components/login-layout"
import Spinner from "../../components/spinner"
import { useAuth } from "../../context/auth"

const IndexPage = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      return
    }
    const [, ...search] = location.search
    const { code, state } = qs.parse(search)

    setLoading(true)
    auth
      .handleLogin("github", {
        state,
        code
      })
      .then(data => {
        if (data.has_account) {
          navigate("/a")
        } else {
          navigate(`/signup/github?${qs.stringify({ ...data.details, state })}`)
        }
      })
  }, [])

  return (
    <LoginLayout pt={3} title={"GitHub"}>
      <Text mb={2} fontWeight="bold" fontSize={4}>
        Signing in
      </Text>
      {loading && (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      )}
    </LoginLayout>
  )
}

export default IndexPage
